import { LogoWhite, RhombusLeft, RhombusRight, phone, tg, up, vk } from "../assets";

import i18n from "../constant/i18n";
import { navLinks } from "../constant";
import { t } from "i18next";

interface ContactProps {
  setCustomPageNumber: React.Dispatch<React.SetStateAction<number>>;
  sections: string[];
  isShortScreen: boolean;
  isSmallScreen: boolean;
  toggleLanguage: () => void;
  phoneContent: string;
  vkContent: string;
  telegramContent: string;
}

const Contact: React.FC<ContactProps> = ({ setCustomPageNumber, sections, isShortScreen, isSmallScreen, toggleLanguage, phoneContent, vkContent, telegramContent }) => {
  const lawyerName = t('lawyerName');

  const splitted = lawyerName.split(" ");

  const firstLine = splitted[0]
  const secondLine = splitted[1] + " " + splitted[2]
  const thirdLine = splitted[3]

  const scrollToSection = (sectionId: string) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const navScroll = (sectionId: string) => {
    const targetIndex = sections.indexOf(sectionId);
    setCustomPageNumber(targetIndex);
    scrollToSection(sectionId);
  };

  const goToTop = () => {
    const targetIndex = sections.indexOf('mainscreen');
    setCustomPageNumber(targetIndex);
    scrollToSection('mainscreen');
  };

  const handleConsultationButtonClick = () => {
    const consultationIndex = sections.indexOf('consultation');
    setCustomPageNumber(consultationIndex);
    scrollToSection('consultation');
  };

  return (
    <div className={`bg-dark-main-bg flex flex-col   justify-center max-[1200px]:pt-20  pb-80 max-2xl:pb-40 
    max-[1200px]:pb-20 
    max-[1200px]:h-full relative max-md:pb-28 max-[400px]:pb-20 h-screen `} id="contact">
      <img src={RhombusRight} className={`absolute z-0 bottom-[40.5%] right-0 max-2xl:bottom-[30%] max-xl:bottom-[25%] max-[1200px]:hidden 
       ${isShortScreen ? 'bottom-[28%]' : ''}`}/>
      <img src={RhombusLeft} className="absolute z-0 top-0 max-[1200px]:hidden" />
      <div className="flex justify-start items-start">
        <h1 className="z-10 text-white font-georgia font-bold text-[40px] mb-20 ml-40 max-lg:ml-20 max-md:ml-10 max-md:mb-10
        max-2xl:text-[30px]  ">
          {t('contactHeader')} 
        </h1>
      </div>
      <div className={`flex items-center z-10 justify-between max-2xl:flex-col 
      max-2xl:items-center max-2xl:justify-center  ${isShortScreen ? 'gap-12' : 'max-2xl:gap-20 max-xl:gap-16 mb-5'}`} >
        <div className="flex flex-col mx-40 items-start justify-center gap-10 
        max-[1610px]:mx-20 max-2xl:flex-row max-2xl:gap-20  max-xl:gap-10 max-[1200px]:flex-col max-[400px]:mx-5">
          <div className="flex items-center gap-5">
            <a href={`tel:${phoneContent}`} target="_blank" className='animation-hover rounded-xl hover:bg-gray-400' rel="noreferrer" >
              <img src={phone} alt="phone" />
            </a>
            <a className='font-georgia leading-normal text-white text-center text-2xl font-normal
            hover:text-gray-400 active:text-gray-400 animation-hover max-sm:text-lg' href={`tel:${phoneContent}`}>{phoneContent}</a>
          </div>
          <div className="flex items-center gap-5">
            <a href={vkContent} target="_blank" className='animation-hover rounded-xl hover:bg-gray-400' rel="noreferrer" >
              <img src={vk} alt="vk" />
            </a>
            <a className='font-georgia leading-normal text-white text-center text-2xl font-normal
            hover:text-gray-400 active:text-gray-400 animation-hover max-sm:text-lg' href={vkContent}>vk.com/id_advokat_fedorov</a>
          </div>
          <div className="flex items-center gap-5">
            <a href={telegramContent} target="_blank" className='animation-hover rounded-xl hover:bg-gray-400  ' rel="noreferrer" >
              <img src={tg} alt="tg" />
            </a>
            <a className='font-georgia leading-normal text-white text-center text-2xl font-normal mr-8
             hover:text-gray-400 active:text-gray-400 animation-hover max-sm:text-lg ' href={telegramContent}>@telegram_nik</a>
          </div>
        </div>

        <div className={`flex  justify-center items-center gap-12 ${isShortScreen && !isSmallScreen ? 'flex-row' : 'flex-col'}`}>
          <h1 className="font-georgia text-[38px] font-bold uppercase text-white text-center leading-loose
          max-2xl:text-[30px] max-xl:text-[24px] max-md:text-[20px] ">
           {t('contactQuestion1')} <br/>
           {t('contactQuestion2')}
          </h1>
          <button className="pulse-white
          bg-white px-5 py-5 rounded-xl shadow-md animation-hover hover:bg-gray-400 hover:text-white
           font-georgia text-xl uppercase text-dark-gray font-bold  max-sm:text-base max-[400px]:mx-5 max-[400px]:py-2"
            onClick={handleConsultationButtonClick}
          >
             {t('signUpForConsultation')}
          </button>
        </div>
        <button onClick={goToTop}>
          <img src={up} className="up-contact  cursor-pointer mb-10 up mr-40 max-2xl:mr-0" />
        </button>
      </div>
      <div className={`absolute bottom-0 w-full max-[1200px]:hidden `}>
        <div className="flex bg-main-bg justify-between items-center px-10 max-md:px-5 py-10 max-lg:flex-col ">
          <div className="flex items-center justify-center">
            <a href="/">
              <img
                src={LogoWhite}
                alt="logo"
                className="mb-1 cursor-pointer max-md:w-24 max-sm:w-14 max-sm:mt-4"
              />
            </a>
            <a
              href="/"
              className="text-white font-georgia text-3xl leading-tight ml-2 max-2xl:text-2xl max-xl:hidden max-lg:block max-md:text-xl max-sm:text-base">
              {firstLine}
              <br />
              {secondLine}
              <br />
              {thirdLine}
            </a>
          </div>
          <ul className='flex justify-center items-center gap-20 z-10 max-[1700px]:gap-14 max-2xl:gap-10  max-lg:mb-10 max-sm:gap-5 max-[450px]:flex-col'>
            {navLinks.map((item) => (
              <li key={item.labelKey}>
                <a
                  href={item.href}
                  className='font-georgia leading-normal text-2xl max-sm:text-base font-normal text-white uppercase cursor-pointer
                            hover:text-gray-400 active:text-gray-400 animation-hover max-2xl:text-xl '
                  onClick={(e) => {
                    e.preventDefault();
                    navScroll(item.section)
                  }}
                >
                  {t(item.labelKey)}
                </a>
              </li>
            ))}
          </ul>
          <a className='font-georgia leading-normal text-2xl font-bold text-white uppercase cursor-pointer
            hover:text-gray-500 active:text-gray-400 animation-hover z-10 max-2xl:text-xl  max-lg:mb-10' href={`tel:${phoneContent}`}>{phoneContent}</a>
          <p onClick={toggleLanguage} className="font-georgia leading-normal text-2xl font-normal uppercase cursor-pointer
             hover:text-gray-400 active:text-gray-400 animation-hover pr-5  max-2xl:text-xl max-md:pr-0 max-sm:text-lg text-white">  {i18n.language}</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
