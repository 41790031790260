import { Banner, Logo } from "../assets";
import React, { useEffect, useState } from "react";

type Props = {
  setIsScreensaverVisible: (state: boolean) => void
}

const Screensaver: React.FC<Props> = ({ setIsScreensaverVisible }) => {
  const [visibleLetters, setVisibleLetters] = useState(0);
  const firstPart = "Адвокат";
  const secondPart = "Федоров Сергей";
  const thirdPart = "Викторович";

  useEffect(() => {
    const totalLength = firstPart.length + secondPart.length + thirdPart.length;

    const interval = setInterval(() => {
      setVisibleLetters((prev) => (prev < totalLength ? prev + 1 : prev));
    }, 100);

    if (visibleLetters >= totalLength) {
      clearInterval(interval);
      setTimeout(() => {
        setIsScreensaverVisible(false);
      }, 500);
    }

    return () => clearInterval(interval);
  }, [visibleLetters]);

  return (
    <div id="screensaver" className="flex items-center justify-center w-full h-screen absolute z-[9999]">
      <div
        className="absolute top-0 right-0 bottom-0 left-0"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: -1,
        }}
      />
      <div className="flex items-end gap-8 z-10 max-lg:flex-col max-lg:items-center">
        <img src={Logo} alt="logo" className="mb-3 max-lg:px-5 max-2xl:w-80 max-sm:w-64 max-[400px]:w-52" />
        <h1
          className="font-georgia text-8xl font-normal text-main-text mb-5 max-2xl:text-[80px] max-lg:text-[70px] max-lg:leading-[80px] max-lg:text-center
            max-sm:text-[60px] max-sm:leading-[60px] max-[400px]:text-[40px] max-[400px]:leading-[40px]"
        >
          {firstPart.split('').map((letter, index) => (
            <span key={index} style={{ opacity: index < visibleLetters ? 1 : 0 }}>
              {letter}
            </span>
          ))}
          <br />
          {secondPart.split('').map((letter, index) => (
            <span key={index + firstPart.length} style={{ opacity: index + firstPart.length < visibleLetters ? 1 : 0 }}>
              {letter}
            </span>
          ))}
          <br />
          {thirdPart.split('').map((letter, index) => (
            <span key={index + secondPart.length} style={{ opacity: index + secondPart.length < visibleLetters ? 1 : 0 }}>
              {letter}
            </span>
          ))}
        </h1>
      </div>
    </div>
  );
};

export default Screensaver;
