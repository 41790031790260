import React, { useEffect, useState } from "react";
import { arrowLeftWhite, arrowRightWhite } from "../assets";

import { API_URL } from "../constant";
import { fetchExampleData } from "../api";
import { t } from "i18next";

interface ExampleData {
    name: string;
    image: string;
}

const Examples = () => {
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [exampleContent, setExampleContent] = useState<ExampleData[]>([]);

    const fetchData = async () => {
        try {
            const mainData = await fetchExampleData();
            setExampleContent(mainData);
        } catch (error) {
            console.error("Ошибка запроса:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const nextPhoto = () => {
        setCurrentPhotoIndex(
            (prevIndex) => (prevIndex + 1) % exampleContent.length
        );
    };

    const prevPhoto = () => {
        setCurrentPhotoIndex(
            (prevIndex) =>
                (prevIndex - 1 + exampleContent.length) % exampleContent.length
        );
    };

    const displayPhotos =
        exampleContent.length > 0 ? [exampleContent[currentPhotoIndex]] : [];

    if (exampleContent.length > 2) {
        displayPhotos.unshift(
            exampleContent[
                (currentPhotoIndex - 1 + exampleContent.length) %
                    exampleContent.length
            ]
        );
        displayPhotos.push(
            exampleContent[(currentPhotoIndex + 1) % exampleContent.length]
        );
    }
    const isHiddenClass =
        exampleContent.length < 3
            ? "max-xl:h-[650px] max-sm:h-[500px] max-sm:w-[400px] max-[550px]:h-[450px] max-[550px]:w-[400px] max-[450px]:h-[400px] max-[450px]:w-[600px]"
            : "hidden";

    return (
        <div
            className={`flex flex-col justify-center max-[1200px]:h-full max-[1200px]:pb-20 max-[1200px]:pt-20 h-screen `}
            id="example"
        >
            <h1 className="w-full text-center text-white font-georgia font-bold text-[50px] mb-16 max-2xl:text-[40px] max-md:text-[35px]">
                {t("exampleHeader")}
            </h1>
            <div className="flex flex-col justify-center items-center">
                <div className="flex justify-center items-center">
                    <div className="flex items-center justify-center gap-5 max-2xl:gap-2 max-xl:gap-0">
                        <button onClick={prevPhoto}>
                            <img
                                src={arrowLeftWhite}
                                alt="left"
                                className="w-full"
                            />
                        </button>
                        {displayPhotos.map((item, index) => (
                            <div>
                                <img
                                    key={index}
                                    src={`${API_URL}/${item.image.replace(
                                        "public",
                                        "storage"
                                    )}`}
                                    alt={`example-${index}`}
                                    className={`${
                                        index === 1
                                            ? "w-[500px] h-[700px] max-[1700px]:h-[700px] max-2xl:h-[600px] max-xl:h-[650px] max-sm:h-[500px] max-sm:w-[400px] max-[550px]:h-[450px] max-[550px]:w-[400px] max-[450px]:h-[400px] max-[450px]:w-[600px]"
                                            : `w-[400px] h-[500px] max-2xl:h-[500px]  max-xl:${isHiddenClass} `
                                    }`}
                                />
                            </div>
                        ))}
                        <button onClick={nextPhoto}>
                            <img
                                src={arrowRightWhite}
                                alt="right"
                                className="w-full "
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Examples;
