interface DotsNavigateProps {
  customPageNumber: number;
  sections: string[];
  handleDotClick: (index: number) => void;
  isSmallScreen: boolean;
  isShortScreen: boolean;
  scrollToSection: (index: number) => void;
  activeSection: number;
}

const DotsNavigate: React.FC<DotsNavigateProps> = ({
  customPageNumber,
  sections,
  handleDotClick,
  isSmallScreen,
  isShortScreen,
  scrollToSection,
  activeSection,
}) => {
  return (
    <div>
      {!isSmallScreen  ? (
        <div className="fixed z-50 top-1/2 right-10 transform -translate-y-1/2 mx-auto max-lg:hidden max-2xl:right-7">
          <div className="flex flex-col space-y-10 z-10 cursor-pointer ml-2 max-sm:space-y-10 mx-auto">
            {sections.map((section, index) => (
              <div
                onClick={() => handleDotClick(index)}
                className={`w-4 h-4 hover:bg-gray-400 animation-hover rounded-sm 
                  first:first-child-triangle last:last-child-triangle
                  ${customPageNumber === index 
                    ? `
                      first:bg-transparent first:border-transparent first:border-b-white
                      last:bg-transparent last:border-transparent last:border-r-white
                      bg-white
                    ` 
                    : `
                      first:bg-transparent first:border-transparent first:border-b-[#4B4B4B] 
                      last:bg-transparent last:border-transparent last:border-r-[#4B4B4B]
                      bg-[#4B4B4B]
                    `
                  }
              `}
              ></div>
            ))}
          </div>
        </div>
      ) : (
        <div className="fixed z-50 top-1/2 right-10 transform -translate-y-1/2 mx-auto max-lg:hidden max-2xl:right-7">
          <div className="flex flex-col space-y-10 z-10 cursor-pointer ml-2 max-sm:space-y-10 mx-auto">
            {sections.map((section, index) => (
              <div
                onClick={() => scrollToSection(index)}
                className={`w-4 h-4 hover:bg-gray-400 animation-hover
                  rounded-full ${activeSection === index ? 'bg-white' : 'bg-[#4B4B4B]'}
              `}
              ></div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DotsNavigate;
