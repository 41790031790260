import { Banner } from "../assets";
import { Link } from "react-scroll";
import { navLinks } from "../constant";
import { t } from "i18next";
import { useState } from "react";

interface NavScrollProps {
    setCustomPageNumber: React.Dispatch<React.SetStateAction<number>>;
    sections: string[];
    phoneContent:string;
}
const NavScroll = ({ setCustomPageNumber, sections, phoneContent }: NavScrollProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleCheckboxChange = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };


    const scrollToSection = (sectionId: string) => {
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const navScroll = (sectionId: string) => {
        const targetIndex = sections.indexOf(sectionId);
        setCustomPageNumber(targetIndex);
        scrollToSection(sectionId);
    };


    return (
        <div className="fixed top-10 right-5 z-[9999] max-lg:hidden">
            <div className="burger cursor-pointer mt-4 z-50 ">
                <input
                    type="checkbox"
                    checked={isMenuOpen}
                    onChange={handleCheckboxChange}
                />
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div
                className={`z-10 w-full h-full fixed top-0 left-0 bg-cover bg-center ${isMenuOpen ? 'block' : 'hidden'
                    }`}
                style={{ backgroundImage: `url(${Banner})` }}
            >
                <div className="flex flex-col items-start justify-center gap-5 mt-32 mx-40">
                    <ul className='nav-links-mobile flex flex-col items-center z-10 space-y-20 my-10'>
                        {navLinks.map((item, index) => (

                            <li
                                key={item.labelKey}
                                className={`${isMenuOpen ? 'fade-in' : ''} animated fadeInPrivacy delay-${index + 1}`}
                            >
                                <a
                                    href={item.href}
                                    className='font-georgia text-2xl text-main-text 
                                     uppercase cursor-pointer hover:text-gray-400
                                    active:text-gray-400 animation-hover max-lg:text-xl max-sm:text-lg'
                                    onClick={() => {
                                        navScroll(item.section);
                                        closeMenu();
                                    }}
 >
                                    {t(item.labelKey)}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <a
                        className="animated fadeInPrivacy delay-5 font-georgia leading-normal text-3xl max-xl:text-2xl
                        font-bold text-main-text uppercase cursor-pointer hover:text-gray-500 active:text-gray-400 animation-hover mt-4 max-lg:text-xl max-sm:text-lg"
                        href={`tel:${phoneContent}`}
                        onClick={closeMenu}
                    >
                        {phoneContent}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NavScroll;
