import { About, AboutMeBg2, like, medal, ten } from "../assets";

import { t } from "i18next";

const AboutMe = () => {
    const data = [
        { imgSrc: ten, text: t('aboutMeData.0') },
        { imgSrc: medal, text: t('aboutMeData.1') },
        { imgSrc: like, text: t('aboutMeData.2') },
    ];

    const aboutMeText = t('aboutMeText')

    return (
        <div className={`relative flex max-[1200px]:h-full h-screen`} id="about">
            <div className="flex items-end">
                <img src={About} alt="about" className="max-lg:hidden" />
            </div>
            <div className="flex flex-col w-full items-center justify-center
            ml-10 mx-20 relative  max-lg:mx-20 max-2xl:ml-0 max-md:mx-10 max-sm:mx-7 ">
                <div className="flex flex-col ">
                <h1 className="w-full text-center text-white font-georgia font-bold text-[50px] mt-10 mb-10 max-2xl:mb-5 max-2xl:text-[40px] max-md:text-[35px]">
                        {t("aboutHeader")}
                    </h1>
                    <p className="text-white font-georgia text-xl font-normal leading-[35px] text-justify max-2xl:leading-[35px] max-2xl:text-[20px] max-xl:leading-[30px] max-sm:text-[18px] max-[400px]:text-[16px]">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: aboutMeText!,
                            }}
                        ></div>
                    </p>
                </div>
                <div className="flex gap-20 mt-20 items-center justify-center max-[1620px]:mt-6 font-georgia text-white text-xl font-normal max-2xl:gap-10 max-sm:gap-0 max-sm:flex-col max-sm:items-start max-lg:pb-32 ">
                    {data.map((item, index) => (
                        <div key={index} className="flex items-center justify-center gap-5 pb-10 ml-5 max-xl:ml-0 ">
                            <img src={item.imgSrc} alt={`item-${index}`} className="max-[1800px]:w-20 max-[1600px]:ml-5  max-xl:w-16" />
                            <p className="text-2xl max-2xl:text-[20px] max-xl:text-base max-sm:text-lg max-[400px]:text-base ">{item.text}</p>
                        </div>
                    ))}
                </div>
                <div className="absolute right-10 bottom-10">
                    <img src={AboutMeBg2} alt="About" className="z-0 h-[400px] max-2xl:h-[350px] max-xl:h-[300px] max-lg:h-[250px] max-lg:hidden" />
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
