import React, { useEffect, useRef, useState } from "react";
import { ServicesBg, clouds } from "../assets";
import { fetchCategoryData, fetchCategorySeoData } from "../api";

import { t } from "i18next";

interface Category {
    categories: Category[];
    description: any;
    image: any;
    meta_description: any;
    meta_h1: any;
    meta_keywords: any;
    meta_title: any;
    name: string;
    preview: any;
    seo_keyword: string;
}

interface Result {
    key: string;
    value: any[];
}

const Services: React.FC = () => {
    const [serviceApiData, setServiceApiData] = useState<Category[]>([]);
    const [seoApiContent, setSeoApiContent] = useState<Result[]>([]);

    const [categories, setCategories] = useState<Category[] | string>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const modal = useRef<HTMLDialogElement>(null);

    const details = document.querySelectorAll("details");

    details.forEach((targetDetail) => {
        targetDetail.addEventListener("click", () => {
            details.forEach((detail) => {
                if (detail !== targetDetail) {
                    detail.removeAttribute("open");
                }
            });
        });
    });

    useEffect(() => {
        if (modalOpen) {
            modal.current?.showModal();
            document.body.classList.add(".stop-scrolling")
        } else {
            modal.current?.close();
            document.body.classList.remove(".stop-scrolling")
        }
    }, [modalOpen]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoryData = await fetchCategoryData();
                setServiceApiData(categoryData);

                const categorySeoData = await fetchCategorySeoData(
                    categoryData
                );
                setSeoApiContent(categorySeoData);
            } catch (error) {
                console.error("Ошибка запроса:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div
            className="relative z-20 bg-[#42404A] max-[1200px]:h-full h-screen"
            id="services"
        >
            <img
                src={ServicesBg}
                alt="About"
                className="w-full h-full object-fill absolute z-0 max-[1700px]:object-cover max-[1350px]:hidden"
            />
            <div className="flex pt-[250px] z-50 flex-col mx-40 relative max-lg:mx-20 max-md:mx-10 max-[1200px]:pt-32 max-[1200px]:pb-20">
                <h1 className="text-white font-georgia font-bold text-[50px] mb-10 max-2xl:text-[40px] max-md:text-[35px]">
                    {t("servicesHeader")}
                </h1>
                <div className="flex flex-col gap-2">
                    {serviceApiData.map((category) => (
                        <>
                            {category.description ? (
                                <button
                                    className="w-max text-white text-3xl font-georgia"
                                    onClick={() => {
                                        setCategories(category.description);
                                        setModalOpen(true);
                                    }}
                                >
                                    {t(category.name)}
                                </button>
                            ) : (
                                <details
                                    className="w-full flex items-center gap-2 justify-between font-georgia"
                                    key={category.name}
                                >
                                    <summary className="text-white text-3xl">
                                        {t(category.name)}
                                    </summary>
                                    {category.categories.map((subCategory) => (
                                        <>
                                            <button
                                                onClick={() => {
                                                    setCategories(
                                                        subCategory.categories
                                                    );
                                                    setModalOpen(true);
                                                }}
                                                className="text-gray-200 text-xl ml-8 my-1 enabled:hover:text-gray-400 transition-colors duration-200 disabled:text-gray-400"
                                                key={subCategory.name}
                                                disabled={
                                                    !subCategory.categories
                                                        .length
                                                }
                                            >
                                                {subCategory.name}
                                            </button>
                                        </>
                                    ))}
                                </details>
                            )}
                        </>
                    ))}
                </div>

                {/* {serviceApiData.map((category: any, index: number) => (
          <div key={index} className="mb-10">
            <h2 className="text-white font-georgia font-bold text-4xl mb-6 max-2xl:text-3xl max-lg:text-2xl max-sm:text-xl">
              {t(category.meta_h1)}

            </h2>
            <div className="grid grid-cols-2 gap-3 mr-80 max-[1350px]:mr-0 max-[1350px]:grid-cols-3 max-[1350px]:gap-6 max-lg:grid-cols-2 max-lg:gap-8 max-sm:grid-cols-1 max-sm:gap-4">
              {seoApiContent
                .find((item) => item.key === category.seo_keyword)?.value
                .map((item, subIndex) => (
                  <div key={subIndex}>
                    <p className="text-white font-georgia text-xl max-sm:text-lg max-[400px]:text-base cursor-pointer hover:text-gray-400 active:text-gray-400 animation-hover ">
                      {item.meta_h1}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        ))} */}
            </div>
            <div className="absolute left-0 bottom-0 ">
                <img src={clouds} alt="clouds" className="z-0 max-lg:hidden" />
            </div>
            {modalOpen && (
                <dialog
                    ref={modal}
                    id="modal"
                    className="bg-black/50 flex items-center justify-center overflow-hidden"
                >
                    <div className="w-max max-w-[1200px] min-w-[700px] h-max max-h-[700px] bg-white rounded-md p-6 flex flex-col gap-6">
                        <div className="flex items-center justify-between">
                            <h3 className="text-xl text-[#454351] font-bold font-georgia">
                                {Array.isArray(categories) ? 'Виды дел:' : 'Описание:' }
                            </h3>
                            <button
                                onClick={() => setModalOpen(false)}
                                className="modal-close w-full py-2"
                            />
                        </div>
                        {Array.isArray(categories) ? (
                            <ul className="flex flex-col gap-2 list-disc px-6">
                                {categories?.map((item) => (
                                    <li className="font-georgia text-lg">
                                        {item.name}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <>
                                <div
                                    className="content overflow-auto"
                                    dangerouslySetInnerHTML={{
                                        __html: categories!,
                                    }}
                                ></div>
                            </>
                        )}
                    </div>
                </dialog>
            )}
        </div>
    );
};

export default Services;
