import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    lawyerName: 'Lawyer Fedorov Sergey Viktorovich',
                    servicesLabel: 'Services',
                    aboutLabel: 'About Me',
                    reviewsLabel: 'Reviews',
                    contactLabel: 'Contact',
                    photoTitles: {
                        1: 'I will see your case through to the end,\napplying all my accumulated experience.\nPrepared ones win.',
                        2: 'Strength in knowledge, victory in experience:\nyour case is my goal.',
                        3: 'Whatever your difficulties,\nI have strategies for their resolution.',
                    },
                    signUpForConsultation: 'sign Up for Consultation',
                    aboutHeader: 'About me',
                    aboutMeText: `
                        <p style="text-indent: 6%">I am a lawyer with many years of experience in protecting the rights and legitimate interests of individuals, as well as legal entities. 
                        My registration number in the register of lawyers of the St. Petersburg Bar Association is 78/8073.</p>
                        <p style="text-indent: 6%">The scope of my professional interests is limitless, but mostly I provide legal assistance in criminal, civil cases, cases of administrative offenses. 
                        In addition, being a certified mediator, I often act as a mediator, professional conciliator, assist in the settlement of disputes arising both at the pre-trial stage and during the consideration of the case in court.</p>
                        <p style="text-indent: 6%">My main task is to provide my clients with highly qualified, timely, effective legal support based on the principles of confidentiality, legality, individual approach, mutual respect, honesty and fairness.</p>
                        <p style="text-indent: 6%">The primary goal of my activity is to achieve the most favorable result for my client.</p> 
                        <p style="text-indent: 6%">Also on a regular basis I improve the level of my professional competence, take part in conferences, forums, seminars, engage in scientific activities in the field of jurisprudence.</p>
                        <p style="margin-top: 20px; text-align: center;">My competent, rational and responsible approach - the key to a successful solution of your problem! </p>
                    `,
                    aboutMeData: [
                        'years of successful\npractice',
                        'over 100\nwon cases',
                        'over 100\npositive reviews',
                    ],
                    servicesHeader: 'Services',
                    consultationHeader: 'Sign up for a consultation on your civil or criminal case',
                    formHeader: 'schedule a consultation',
                    formName: 'Full Name',
                    formNumber: 'Phone Number',
                    formEmail: 'Email Address',
                    formDescription: 'Description',
                    formFile: 'Attach File',
                    formButton: 'send',
                    reviewsHeader: 'Client Reviews',
                    exampleHeader: 'Successful Case Examples',
                    contactHeader: 'Contact Us',
                    contactQuestion1: 'Have questions?',
                    contactQuestion2:'Sign up for a consultation!'
                    
                },
            },
            ru: {
                translation: {
                    lawyerName: 'Адвокат Федоров Сергей Викторович',
                    servicesLabel: 'Услуги',
                    aboutLabel: 'Обо мне',
                    reviewsLabel: 'Отзывы',
                    contactLabel: 'Контакты',
                    photoTitles: {
                        1: 'Доведу ваше дело до конца\nприкладывая весь накопленный опыт.\nПобеждают подготовленные.',
                        2: 'Сила в знаниях, победа в опыте:\nваше дело - моя цель.',
                        3: 'Каковы бы ни были ваши трудности,\n у меня есть стратегии для их решения.',
                    },
                    signUpForConsultation: 'записаться на консультацию',
                    aboutHeader: 'Обо мне',
                    aboutMeText: `
                        <p style="text-indent: 6%">Я являюсь адвокатом с многолетним опытом защиты прав и законных интересов физических, а также юридических лиц. 
                        Мой регистрационный номер в реестре адвокатов Адвокатской палаты г. Санкт-Петербурга 78/8073.</p>
                        <p style="text-indent: 6%">Сфера моих профессиональных интересов безгранична, однако преимущественно я оказываю юридическую помощь по уголовным, гражданским делам, делам об административных правонарушениях. 
                        Кроме того, являясь дипломированным медиатором, я нередко выступаю как посредник, профессиональный примиритель, оказываю содействие в урегулировании споров, возникших как на досудебной стадии, так и при рассмотрении дела в суде.</p> 
                        <p style="text-indent: 6%">Основная моя задача заключаются в оказании своим доверителям высококвалифицированной, своевременной, эффективной юридической поддержки, базирующейся на принципах конфиденциальности, законности, индивидуального подхода, взаимоуважения, честности и справедливости.</p> 
                        <p style="text-indent: 6%">Первостепенной целью моей деятельности является достижение наиболее благоприятного результата для моего доверителя.</p> 
                        <p style="text-indent: 6%">Также на постоянной основе я совершенствую уровень своей профессиональной компетенции, принимаю участие в конференциях, форумах, семинарах, занимаюсь научной деятельностью в области юриспруденции.</p>
                        <p style="margin-top: 20px; text-align: center;">Мой грамотный, рациональный и ответственный подход – залог успешного решения Вашей проблемы!</p>
                    `,
                    aboutMeData: [
                        'лет успешной\nпрактики',
                        'более 100\nвыигранных дел',
                        'более 100\nположительных отзывов',
                    ],
                    servicesHeader: 'Услуги',
                    consultationHeader: 'Запишись на консультацию  по вашему гражданскому  или уголовному делу',
                    formHeader: 'записаться на консультацию',
                    formName: 'ФИО',
                    formNumber: 'Номер телефона',
                    formEmail: 'Адрес электронной почты',
                    formDescription: 'Описание',
                    formFile: 'Прикрепить файл',
                    formButton: 'отправить',
                    reviewsHeader: 'Отзывы клиентов',
                    exampleHeader: 'Примеры выигранных дел',
                    contactHeader:'Контакты',
                    contactQuestion1:'остались вопросы?',
                    contactQuestion2:'Запишись на консультацию!'
                },
            },
        },
        lng: 'ru',
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
