import { LogoDark, LogoWhite } from "../assets";
import React, { useEffect, useState } from "react";

import { Link } from 'react-scroll';
import NavMobile from "./NavMobile";
import i18n from "../constant/i18n";
import { navLinks } from "../constant";
import { useTranslation } from 'react-i18next';

interface NavProps {
    setCustomPageNumber: React.Dispatch<React.SetStateAction<number>>;
    sections: string[];
    toggleLanguage: () => void;
    phoneContent:string;
}

const Nav: React.FC<NavProps> = ({ setCustomPageNumber, sections, toggleLanguage, phoneContent}) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const lawyerName = t('lawyerName');

    const splitted = lawyerName.split(" ");

    const firstLine = splitted[0]
    const secondLine = splitted[1] + " " + splitted[2]
    const thirdLine = splitted[3]

    const handleCheckboxChange = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isMenuOpen]);

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const scrollToSection = (sectionId: string) => {
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const navScroll = (sectionId: string) => {
        const targetIndex = sections.indexOf(sectionId);
        setCustomPageNumber(targetIndex);
        scrollToSection(sectionId);
    };

    return (
        <div className={`nav-bar flex flex-col ${isMenuOpen ? "bg-white " : ""}`}>
            <div className="flex justify-between items-center pt-2 px-10 max-md:px-5 ">
                <div className="burger hidden cursor-pointer max-lg:block mt-4">
                    <input
                        type="checkbox"
                        checked={isMenuOpen}
                        onChange={handleCheckboxChange}
                    />
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="flex items-center justify-center">
                    <a href="/">
                        <img
                            src={isMenuOpen ? LogoDark : LogoWhite}
                            alt="logo"
                            className="mb-1 cursor-pointer max-md:w-24 max-sm:w-14 max-sm:mt-4"
                        />
                    </a>
                    <a href="/" className={`logo  ${isMenuOpen ? "text-black" : "text-white"} font-georgia text-3xl leading-tight ml-2 max-2xl:text-2xl max-xl:hidden max-lg:block max-md:text-xl max-sm:text-base`}>
                        {firstLine}
                        <br />
                        {secondLine}
                        <br />
                        {thirdLine}
                    </a>
                </div>
                <ul className='flex justify-center items-center gap-20 max-[1700px]:gap-14 max-2xl:gap-10 max-lg:hidden z-10'>
                    {navLinks.map((item) => (
                        <li key={item.labelKey}>
                            <Link
                                to={item.href.substring(1)}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={500}
                                className='font-georgia leading-normal text-2xl font-normal text-white uppercase cursor-pointer hover:text-gray-400 active:text-gray-400 animation-hover max-2xl:text-xl'
                                onClick={() => {
                                    navScroll(item.section);
                                }}
                            >
                                {t(item.labelKey)}
                            </Link>
                        </li>
                    ))}
                </ul>
                <a className='font-georgia leading-normal text-2xl font-bold text-white uppercase cursor-pointer
            hover:text-gray-500 active:text-gray-400 animation-hover max-2xl:text-xl max-lg:hidden z-10' href={`tel:${phoneContent}`}>{phoneContent}</a>
                <p onClick={toggleLanguage} className={`font-georgia leading-normal text-2xl font-normal uppercase cursor-pointer z-50
                   hover:text-gray-400 active:text-gray-400 animation-hover pr-5 max-2xl:text-xl max-md:pr-0 max-sm:text-lg ${isMenuOpen ? "text-main-text" : "text-white  "}`}>
                    {i18n.language}
                </p>
            </div>
            {isMenuOpen && <NavMobile isOpen={isMenuOpen} closeMenu={closeMenu} phoneContent={phoneContent}/>}
        </div>
    );
}

export default Nav;
