import {
    AboutMe,
    Consultation,
    Contact,
    Examples,
    MainScreen,
    Reviews,
    Screensaver,
    Services,
} from "../components";
import { AboutMeBg, ExampleBg, MainBg } from "../assets";
import { DotsNavigate, Footer, NavScroll } from "../section";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import ReactPageScroller from "react-page-scroller";
import { useTranslation } from "react-i18next";

type HomeProps = {
    metaTitle: string;
    metaDescription: string;
    phoneContent: string;
    vkContent: string;
    telegramContent: string;
};

const mountedStyle = {
    animation: "fadeIn 700ms ease-in",
};

const unmountedStyle = {
    animation: "fadeOut 700ms ease-out",
    animationFillMode: "forwards",
};

const Home = ({ ...props }: HomeProps) => {
    const commonBackgroundStyle: React.CSSProperties = {
        backgroundImage: `url(${MainBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 0,
    };

    const commonAboutBackgroundStyle: React.CSSProperties = {
        backgroundImage: `url(${AboutMeBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 0,
    };

    const commonExampleBackgroundStyle: React.CSSProperties = {
        backgroundImage: `url(${ExampleBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 0,
    };

    const { i18n } = useTranslation();
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1200
    );
    const [isShortScreen, setIsShortScreen] = useState(
        window.innerHeight <= 900
    );
    const [customPageNumber, setCustomPageNumber] = useState(0);
    const [activeSection, setActiveSection] = useState(0);
    const sections = [
        "mainscreen",
        "about",
        "services",
        "consultation",
        "reviews",
        "example",
        "contact",
    ];
    const [isDotsNavigateVisible, setIsDotsNavigateVisible] = useState(true);
    const [isScreensaverVisible, setIsScreensaverVisible] = useState(true);

    const toggleLanguage = () => {
        i18n.changeLanguage(i18n.language === "en" ? "ru" : "en");
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1200);
            setIsShortScreen(window.innerHeight <= 900);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsDotsNavigateVisible(false);
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, []);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const sectionOffsets = sections.map((section) => {
            const element = document.getElementById(section);
            return element ? element.offsetTop : 0;
        });

        for (let i = 0; i < sectionOffsets.length; i++) {
            if (
                i === sectionOffsets.length - 1 ||
                (scrollPosition >= sectionOffsets[i] &&
                    scrollPosition < sectionOffsets[i + 1])
            ) {
                setActiveSection(i);
                break;
            }
        }
    };

    const scrollToSection = (index: number) => {
        const sectionElement = document.getElementById(sections[index]);
        if (sectionElement) {
            window.scrollTo({
                top: sectionElement.offsetTop,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleDotClick = (index: number) => {
        setCustomPageNumber(index);
    };
    const isMainScreen = customPageNumber === 0;

    useEffect(() => {
        if (isScreensaverVisible) {
            document.documentElement.classList.add("no-scroll");
        } else {
            document.documentElement.classList.remove("no-scroll");
        }
        return () => {
            document.documentElement.classList.remove("no-scroll");
        };
    }, [isScreensaverVisible]);

    return (
        <div>
            <Helmet>
                <title>{props.metaTitle}</title>
                <meta name="description" content={props.metaDescription} />
            </Helmet>
            <section
                style={isScreensaverVisible ? mountedStyle : unmountedStyle}
            >
                <Screensaver
                    setIsScreensaverVisible={setIsScreensaverVisible}
                />
            </section>
            <>
                <section className={isDotsNavigateVisible ? "hidden" : "block"}>
                    <DotsNavigate
                        sections={sections}
                        customPageNumber={customPageNumber}
                        handleDotClick={handleDotClick}
                        isSmallScreen={isSmallScreen}
                        isShortScreen={isShortScreen}
                        scrollToSection={scrollToSection}
                        activeSection={activeSection}
                    />
                </section>
                {props && !isScreensaverVisible ? (
                    <>
                        {!isSmallScreen ? (
                            <div>
                                {!isMainScreen && (
                                    <section>
                                        <NavScroll
                                            setCustomPageNumber={
                                                setCustomPageNumber
                                            }
                                            sections={sections}
                                            phoneContent={props.phoneContent}
                                        />
                                    </section>
                                )}
                                <ReactPageScroller
                                    customPageNumber={customPageNumber}
                                    pageOnChange={(pageNumber: number) =>
                                        setCustomPageNumber(pageNumber)
                                    }
                                >
                                    <section style={commonBackgroundStyle}>
                                        <MainScreen
                                            scrollToSection={scrollToSection}
                                            handleDotClick={handleDotClick}
                                            setCustomPageNumber={
                                                setCustomPageNumber
                                            }
                                            sections={sections}
                                            isDotsNavigateVisible={
                                                isDotsNavigateVisible
                                            }
                                            toggleLanguage={toggleLanguage}
                                            phoneContent={props.phoneContent}
                                        />
                                    </section>
                                    <section
                                        className="z-[9999]"
                                        style={commonAboutBackgroundStyle}
                                    >
                                        <AboutMe />
                                    </section>
                                    <section>
                                        <Services />
                                    </section>
                                    <section>
                                        <Consultation
                                            isShortScreen={isShortScreen}
                                        />
                                    </section>
                                    <section>
                                        <Reviews />
                                    </section>
                                    <section
                                        style={commonExampleBackgroundStyle}
                                    >
                                        <Examples />
                                    </section>
                                    <section>
                                        <Contact
                                            toggleLanguage={toggleLanguage}
                                            isShortScreen={isShortScreen}
                                            isSmallScreen={isSmallScreen}
                                            setCustomPageNumber={
                                                setCustomPageNumber
                                            }
                                            sections={sections}
                                            phoneContent={props.phoneContent}
                                            vkContent={props.vkContent}
                                            telegramContent={
                                                props.telegramContent
                                            }
                                        />
                                    </section>
                                </ReactPageScroller>
                            </div>
                        ) : (
                            <div>
                                <section style={commonBackgroundStyle}>
                                    <MainScreen
                                        setCustomPageNumber={
                                            setCustomPageNumber
                                        }
                                        scrollToSection={scrollToSection}
                                        handleDotClick={handleDotClick}
                                        sections={sections}
                                        isDotsNavigateVisible={
                                            isDotsNavigateVisible
                                        }
                                        toggleLanguage={toggleLanguage}
                                        phoneContent={props.phoneContent}
                                    />
                                </section>
                                <section
                                    className="pt-20 max-lg:pt-16 max-sm:pt-10"
                                    style={commonAboutBackgroundStyle}
                                >
                                    <AboutMe />
                                </section>
                                <section>
                                    <Services />
                                </section>
                                <section>
                                    <Consultation
                                        isShortScreen={isShortScreen}
                                    />
                                </section>
                                <section>
                                    <Reviews />
                                </section>
                                <section style={commonExampleBackgroundStyle}>
                                    <Examples />
                                </section>
                                <div>
                                    <section>
                                        <Contact
                                            toggleLanguage={toggleLanguage}
                                            isShortScreen={isShortScreen}
                                            setCustomPageNumber={
                                                setCustomPageNumber
                                            }
                                            sections={sections}
                                            isSmallScreen={isSmallScreen}
                                            phoneContent={props.phoneContent}
                                            vkContent={props.vkContent}
                                            telegramContent={
                                                props.telegramContent
                                            }
                                        />
                                    </section>
                                    <section>
                                        <Footer
                                            toggleLanguage={toggleLanguage}
                                            phoneContent={props.phoneContent}
                                        />
                                    </section>
                                </div>
                            </div>
                        )}
                    </>
                ) : null}
            </>
        </div>
    );
};

export default Home;
