import { LogoWhite } from "../assets";
import React from "react";
import i18n from "../constant/i18n";
import { navLinks } from "../constant";
import { t } from "i18next";

interface FooterProps {
  toggleLanguage: () => void;
  phoneContent:string;
}

const Footer: React.FC<FooterProps> = ({ toggleLanguage, phoneContent }) => {
  const lawyerName = t('lawyerName');
  const [firstLine, ...rest] = lawyerName.split(' ');
  const secondLine = rest.join(' ');

  return (
    <div className="flex bg-main-bg justify-between items-center px-10 max-md:px-5 py-10 max-lg:flex-col">
      <div className="flex items-center justify-center">
        <a href="/">
          <img
            src={LogoWhite}
            alt="logo"
            className="mb-8 cursor-pointer max-md:w-24 max-sm:w-14 max-sm:mt-4"
          />
        </a>
        <a
          href="/"
          className="text-white font-georgia text-3xl leading-tight ml-2 max-2xl:text-2xl max-xl:hidden max-lg:block max-md:text-xl max-sm:text-base"
        >
          {firstLine}
          <br />
          {secondLine}
        </a>
      </div>
      <ul className='flex justify-center items-center gap-20 z-10 max-[1700px]:gap-14 max-2xl:gap-10 max-lg:mb-10 max-sm:gap-5 max-[450px]:flex-col'>
        {navLinks.map((item) => (
          <li key={item.labelKey}>
            <a
              href={item.href}
              className='font-georgia leading-normal text-2xl max-sm:text-base font-normal text-white uppercase cursor-pointer
                        hover:text-gray-400 active:text-gray-400 animation-hover max-2xl:text-xl '
              onClick={(e) => {
                e.preventDefault();
                const targetElement = document.getElementById(item.href.substring(1));
                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop - 100,
                    behavior: 'smooth',
                  });
                }
              }}
            >
              {t(item.labelKey)}
            </a>
          </li>
        ))}
      </ul>
      <a
        className='font-georgia leading-normal text-2xl font-bold text-white uppercase cursor-pointer
            hover:text-gray-500 active:text-gray-400 animation-hover z-10 max-2xl:text-xl  max-lg:mb-10'
        href={`tel:${phoneContent}`}
      >
        {phoneContent}
      </a>
      <p
        onClick={toggleLanguage}
        className="font-georgia leading-normal text-2xl font-normal uppercase cursor-pointer
             hover:text-gray-400 active:text-gray-400 animation-hover pr-5 max-2xl:text-xl max-md:pr-0 max-sm:text-lg text-white"
      >
        {i18n.language}
      </p>
    </div>
  );
};

export default Footer;
