import {
    MainPhoto,
    MainPhoto2,
    MainPhoto3,
    RhombusBack,
    RhombusUp,
    arrow,
} from "../assets";
import React, { useEffect, useState } from "react";
import { t, use } from "i18next";

import { Nav } from "../section";

interface MainScreenProps {
    setCustomPageNumber: React.Dispatch<React.SetStateAction<number>>;
    sections: string[];
    isDotsNavigateVisible: boolean;
    toggleLanguage: () => void;
    scrollToSection: (index: number) => void;
    handleDotClick: (index: number) => void;
    phoneContent: string;
}

const MainScreen: React.FC<MainScreenProps> = ({
    setCustomPageNumber,
    sections,
    isDotsNavigateVisible,
    toggleLanguage,
    scrollToSection,
    handleDotClick,
    phoneContent,
}) => {
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [animate, setAnimate] = useState(true);

    const photos = [
        {
            id: 1,
            title: <>{t("photoTitles.1")}</>,
            url: `${MainPhoto}`,
        },
        {
            id: 2,
            title: <>{t("photoTitles.2")}</>,
            url: `${MainPhoto2}`,
        },
        {
            id: 3,
            title: <>{t("photoTitles.3")}</>,
            url: `${MainPhoto3}`,
        },
    ];

    const handleConsultationButtonClick = () => {
        const secondSectionIndex = 3;
        scrollToSection(secondSectionIndex);
        handleDotClick(secondSectionIndex);
    };

    const goToAbout = () => {
        const secondSectionIndex = 1;
        scrollToSection(secondSectionIndex);
        handleDotClick(secondSectionIndex);
    };

    useEffect(() => {
        const sliderInterval = setInterval(() => {
            setAnimate(false);

            setTimeout(() => {
                setCurrentPhotoIndex(
                    (prevIndex) => (prevIndex + 1) % photos.length
                );
                setAnimate(true);
            }, 1000);
        }, 6000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, [photos.length]);

    return (
        <div id="mainscreen">
            <div className="flex justify-center ">
                <img
                    src={RhombusBack}
                    alt="about"
                    className={
                        isDotsNavigateVisible
                            ? "hidden"
                            : "block absolute z-0 right-32 max-lg:top-20"
                    }
                />
            </div>
            <section
                className={
                    isDotsNavigateVisible
                        ? "hidden"
                        : "block absolute top-0 w-full z-[9998]"
                }
            >
                <Nav
                    setCustomPageNumber={setCustomPageNumber}
                    sections={sections}
                    toggleLanguage={toggleLanguage}
                    phoneContent={phoneContent}
                />
            </section>
            <div
                className={`relative  max-[1200px]:h-full 
      h-screen flex  justify-center items-end max-[1200px]:pt-20 max-sm:pt-10}`}
            >
                <div
                    className={`flex justify-center items-center max-[2560px]:mx-40 max-[2000px]:mx-20 max-[1200px]:flex-col-reverse   max-sm:flex-col max-sm:mt-10 max-sm:mx-5
                    ${animate ? "animate-slide" : "animate-slide-out"}  
                    ${
                        photos[currentPhotoIndex].id === 2
                            ? "flex-row-reverse "
                            : ""
                    }`}
                >
                    <div
                        className={`flex flex-col justify-center items-start mb-[10%] max-[1200px]:mb-0  max-[1200px]:flex-row max-[1200px]:pt-5 max-[1200px]:gap-10 max-[1000px]:flex-col  max-[1000px]:gap-5  max-[1000px]:items-center ${
                            photos[currentPhotoIndex].id === 1
                                ? "max-[1000px]:pt-8"
                                : ""
                        }`}
                    >
                        <p
                            className={`text-white font-georgia font-normal text-[45px] mb-10 max-[1920px]:text-[40px] max-[1200px]:mb-5 max-[1000px]:mb-0 max-sm:text-center max-sm:hidden
              max-[1660px]:text-[35px] max-2xl:text-[30px] max-[1380px]:text-[25px] 
              ${
                  photos[currentPhotoIndex].id === 2
                      ? "mr-40 mb-20 z-50 max-2xl:mr-20 max-[1200px]:mr-0  max-[1200px]:mb-5 max-[1000px]:mb-0"
                      : ""
              }`}
                        >
                            {t(`photoTitles.${photos[currentPhotoIndex].id}`)
                                .split("\n")
                                .map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                        </p>
                        <p className="text-white font-georgia font-normal text-[20px] text-center hidden max-sm:block mx-10 max-[450px]:mx-0">
                            {t(`photoTitles.${photos[currentPhotoIndex].id}`)}
                        </p>
                        <button
                            className="pulse-white w-[500px] max-2xl:w-[400px] z-10
              max-2xl:py-2 max-xl:w-[300px] max-[1200px]:w-[280px]  max-[1200px]:mb-28  max-sm:mb-5 max-sm:w-[250px] max-[1200px]:text-base max-[1200px]:mt-2 max-[1000px]:mt-0
            bg-white px-5 py-5 rounded-xl shadow-md animation-hover hover:bg-gray-400 hover:text-white font-georgia text-xl uppercase text-[#202E40] font-bold max-[400px]:mx-5 max-sm:text-[12px] max-[1000px]:px-2 "
                            onClick={handleConsultationButtonClick}
                        >
                            {t("signUpForConsultation")}
                        </button>
                    </div>
                    <div className="flex items-end justify-end mr-10 max-[1200px]:mr-0 max-lg:mr-0 max-[1200px]:items-center">
                        <img
                            src={photos[currentPhotoIndex].url}
                            alt="about"
                            className={`z-10 h-[1000px] max-[2560px]:h-[850px] max-[1920px]:h-[750px] max-[1750px]:h-[700px] max-[1580px]:h-[650px]
                  max-[1440px]:h-[600px] max-[1380px]:h-[500px] max-[1200px]:h-[450px] max-[1000px]:mt-5 max-sm:mt-0 max-sm:h-[350px]  ${
                      photos[currentPhotoIndex].id === 1
                          ? "max-[1000px]:h-[400px] max-[500px]:h-[320px] max-[400px]:h-[349px] max-[350px]:h-[320px]"
                          : photos[currentPhotoIndex].id === 2
                          ? "max-sm:h-[390px]  max-[500px]:h-[367px]  max-[400px]:h-[390px]"
                          : photos[currentPhotoIndex].id === 3
                          ? "max-sm:h-[360px]"
                          : ""
                  }`}
                        />
                    </div>
                </div>
                <div
                    className="absolute left-1/2 transform -translate-x-1/2 bottom-0 max-[1200px]:bottom-2 arrow z-10 "
                    onClick={goToAbout}
                >
                    <img
                        src={arrow}
                        alt="arrow"
                        className="bounce cursor-pointer max-md:w-24 max-[400px]:w-16"
                    />
                </div>
                <img
                    src={RhombusUp}
                    alt="about"
                    className="absolute mt-20 w-[450px] z-10 max-[1500px]:w-[350px] top-[40%]  right-0 max-[1380px]:hidden"
                />
            </div>
        </div>
    );
};

export default MainScreen;
