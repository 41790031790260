import { API_URL } from "../constant/";
import axios from "axios";

export const fetchMainData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/main`);
    if (response.status === 200 && response.data.message === 'Данные успешно найдены') {
      return response.data.data;
    } else {
      throw new Error('Данные не найдены');
    }
  } catch (error) {
    throw new Error(`Ошибка запроса`);
  }
};


export const fetchReviewData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/review`);
    if (response.status === 200 && response.data.message === 'Данные успешно найдены') {
      return response.data.data.data;
    } else {
      throw new Error('Данные не найдены');
    }
  } catch (error) {
    throw new Error(`Ошибка запроса`);
  }
}

export const fetchCategoryData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/service/category`);
    if(response.status === 200) {
      return response.data.data;
    } else {
      throw new Error('Данные не найдены')
    }
  } catch (error) {
    throw new Error(`Ошибка запроса`);
  }
}

interface CategoryData {
  seo_keyword: string;
}

interface ApiResponse {
  data: {
    categories: string[];
  };
}

interface Result {
  key: string;
  value: string[];
}

export const fetchCategorySeoData = async (data: CategoryData[]) => {
  try {
    const seoData: Result[] = [];

    await Promise.all(data.map(async (key: CategoryData) => {
      const response = await axios.get<ApiResponse>(`${API_URL}/api/service/category/show/${key.seo_keyword}`);

      const jsonObject: Result = {
        key: key.seo_keyword,
        value: response.data.data.categories,
      };

      seoData.push(jsonObject);
    }));

    return seoData;
  } catch (error) {
    throw new Error(`Ошибка запроса: `);
  }
};

export const fetchExampleData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/example`);
    if (response.status === 200 && response.data.message === 'Данные успешно найдены') {
      return response.data.data.data;
    } else {
      throw new Error('Данные не найдены');
    }
  } catch (error) {
    throw new Error(`Ошибка запроса`);
  }
}
