import React, { useEffect } from "react";

import { Banner } from "../assets";
import { navLinks } from "../constant";
import { t } from "i18next";

type NavMobileProps = {
    isOpen: boolean;
    closeMenu: () => void;
    phoneContent: string;
};

const NavMobile: React.FC<NavMobileProps> = ({ isOpen, closeMenu, phoneContent }) => {

    useEffect(() => {
        if (isOpen) {
            document.documentElement.classList.add("no-scroll");
        } else {
            document.documentElement.classList.remove("no-scroll");
        }
        return () => {
            document.documentElement.classList.remove("no-scroll");
        };
    }, [isOpen]);

    return (
        <div
            onClick={closeMenu}
            className="z-50 mobile-nav "
            style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: isOpen ? '100vh' : 'auto',
                overflow: isOpen ? 'hidden' : 'auto',
            }}
        >
            <div className={`max-lg:block  ${isOpen ? 'fade-in' : ''}`} >
                <div className="flex flex-col items-center justify-center gap-5 mt-5">
                    <ul className='nav-links-mobile flex flex-col items-center z-10 space-y-20 my-10'>
                        {navLinks.map((item, index) => (
                            <li
                                key={item.labelKey}
                                className={`${isOpen ? 'fade-in' : ''} animated fadeInPrivacy delay-${index + 1}`}
                            >
                                <a
                                    href={item.href}
                                    className='font-georgia text-2xl text-main-text 
                                     uppercase cursor-pointer hover:text-gray-400
                                    active:text-gray-400 animation-hover max-lg:text-xl max-sm:text-lg'
                                    onClick={closeMenu}
                                >
                                    {t(item.labelKey)}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <a
                        className='animated fadeInPrivacy delay-5 font-georgia leading-normal text-2xl font-bold 
                        text-main-text uppercase cursor-pointer hover:text-gray-500 active:text-gray-400
                         animation-hover mt-4 max-lg:text-xl  max-sm:text-lg'
                        href={`tel:${phoneContent}`}
                    >
                        {phoneContent}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NavMobile;
