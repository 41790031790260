import { ChangeEvent, useState } from "react";
import { ConsultationBg, paperclip } from "../assets";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { API_URL } from "../constant";
import InputMask from 'react-input-mask';
import axios from "axios";
import { t } from "i18next";

interface ConsultationProps {
    isShortScreen: boolean;
}

const Consultation = ({ isShortScreen }: ConsultationProps) => {
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
    } = useForm();

    const consultationForm: SubmitHandler<FieldValues> = async (data) => {
        try {
            const currentUrl = window.location.href;
            data.url = currentUrl;
            const response = await axios.post(
                `${API_URL}/api/feedback/store`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                console.log("Данные успешно отправлены");
                reset();
                setErrors({});
                setValue("phone", "");
                setSelectedFile(null);
            }
        } catch (error: any) {
            if (error.response && error.response.status === 422) {

                setErrors(error.response.data.errors);
            }
            if (error.response) {
                console.log("Response data:", error.response.data);
            }
        }
    };


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        setSelectedFile(file);
        setValue("file", file);
    };

    const handleFileRemove = () => {
        setSelectedFile(null);
        setValue("file", "");
    };

    return (
        <div className={`flex items-center justify-center bg-[#211F2B] relative 
        pb-10 max-[1200px]:pb-40 max-[1200px]:pt-20 max-[1200px]:h-full h-screen`} id="consultation">
            <div className="absolute z-0 left-0" >
                <img src={ConsultationBg} alt="consultation" className="z-0" />
            </div>
            <div className="flex justify-center 
            items-center  pt-10  max-[1600px]:flex-col max-[1600px]:mx-20 max-xl:mx-20 max-md:mx-10 max-md:pt-32 
             max-sm:mx-5 " >
                <div className={`flex items-center w-[40%] max-[1600px]:w-full ${isShortScreen ? 'hidden' : 'block'}`}>
                    <h2 className="z-10 text-white ml-20 text-center font-georgia text-[45px] font-semibold leading-normal  max-[1800px]:text-[40px] max-[1800px]:ml-6  max-2xl:text-[38px] max-[1600px]:text-[32px] max-lg:text-[30px] max-md:text-[26px] max-sm:text-[20px] max-2xl:ml-0 max-[1600px]:pb-3 ">
                    {t('consultationHeader')}
                    </h2>
                </div>
                <div className="z-10 bg-white w-[900px] ml-20 rounded-xl max-[1800px]:ml-6 max-[1200px]:w-full max-[1200px]:mx-20 max-md:mx-5 max-[350px]:mx-2"
                    style={{
                        boxShadow: "0px 4.95623px 4.95623px 0px rgba(0, 0, 0, 0.25);"
                    }}
                >
                    <h1 className="text-main-text font-georgia font-normal text-[36px] mt-12 text-center uppercase max-2xl:text-3xl max-lg:text-2xl  max-md:text-xl max-sm:text-lg max-[400px]:text-base  ">
                    {t('formHeader')}
                    </h1>
                    <form className="p-8 max-md:p-5" onSubmit={handleSubmit(consultationForm)}>
                        <div className="flex items-center mt-10 justify-between mb-5 max-lg:mt-2 max-md:flex-col max-[1280px]:mt-0">
                            <label className="w-[250px]  max-md:w-full">
                                <span className="text-main-text font-georgia text-xl  font-medium max-md:text-lg max-sm:text-base max-[400px]:text-sm  mr-2">   {t('formName')} </span>
                            </label>
                            <div className="flex flex-col w-full">
                                <input
                                    type="text"
                                    className="flex-grow rounded-[10px] h-12 pl-2  font-georgia text-xl font-medium max-[1440px]:text-base max-md:text-sm max-[500px]:h-10 max-md:w-full bg-main-gray"
                                    {...register("author")}
                                />
                                {errors.author && (
                                    <div className="text-red-400">{errors.author[0]}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-between mb-5  max-md:flex-col">
                            <label className="w-[250px]  max-md:w-full">
                                <span className="text-main-text font-georgia text-xl font-medium max-md:text-lg max-sm:text-base max-[400px]:text-sm  mr-2"> {t('formNumber')}</span>
                            </label>
                            <div className="flex flex-col w-full">
                                <InputMask
                                    mask="+7 (999) 999-99-99"
                                    className="flex-grow rounded-[10px] h-12 pl-2 font-georgia text-xl font-medium max-[1440px]:text-base max-md:text-sm max-[500px]:h-10  max-md:w-full bg-main-gray"
                                    {...register("phone")}
                                />
                                {errors.phone && (
                                    <div className="text-red-400 ">{errors.phone[0]}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-between mb-5 max-md:flex-col">
                            <label className="w-[250px]  max-md:w-full">
                                <span className="text-main-text font-georgia text-xl font-medium max-md:text-lg max-sm:text-base max-[400px]:text-sm  mr-2"> {t('formEmail')}</span>
                            </label>
                            <div className="flex flex-col w-full">
                                <input
                                    type="text"
                                    className="flex-grow rounded-[10px] h-12 pl-2 font-georgia text-xl font-medium max-[1440px]:text-lg max-sm:text-base max-[400px]:text-sm  max-[500px]:h-10  max-md:w-full bg-main-gray"
                                    {...register("email")}
                                />
                                {errors.email && (
                                    <div className="text-red-400">{errors.email[0]}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-between mb-5 max-md:flex-col">
                            <label className="w-[250px]  max-md:w-full">
                                <span className="text-main-text font-georgia text-xl font-medium  max-md:text-lg max-sm:text-base max-[400px]:text-sm   mr-2"> {t('formDescription')}</span>
                            </label>
                            <div className="flex flex-col w-full">
                                <textarea
                                    {...register("text")}
                                    className="font-georgia text-xl font-medium
                                bg-main-gray p-2 rounded-[10px]  h-40 max-h-52 min-h-44 max-lg:h-32"
                                />
                                {errors.text && (
                                    <div className="text-red-400">{errors.text[0]}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-between items-center mt-5 max-lg:flex-col max-lg:items-end  max-lg:gap-5 ">
                            <div className="flex flex-col items-start max-lg:items-end ">
                                <div className="flex items-center justify-start gap-5 max-sm:gap-2 max-[480px]:flex-col ">
                                    <label htmlFor="file" className="cursor-pointer flex items-center justify-center gap-5 ">
                                        <span className="hover:text-gray-500 active:text-gray-400 animation-hover text-main-text font-georgia text-xl font-medium max-md:text-lg max-sm:text-base max-[400px]:text-sm "> {t('formFile')} </span>
                                        <img src={paperclip} alt="paperclip" className="cursor-pointer max-md:w-10  max-sm:w-8 " />
                                    </label>
                                    <input
                                        type="file"
                                        id="file"
                                        className="hidden"
                                        {...register("file")}
                                        onChange={handleFileChange}
                                    />
                                    {selectedFile ? (
                                        <div className="flex gap-5 items-center justify-center">
                                            <p className="text-light-gray font-georgia text-base font-medium">
                                                {selectedFile.name.length > 20
                                                    ? `${selectedFile.name.substring(0, 20)}...`
                                                    : selectedFile.name
                                                }
                                            </p>
                                            <div
                                                className="cursor-pointer text-light-gray font-georgia text-xl font-medium hover:text-gray-500 active:text-gray-400 animation-hover"
                                                onClick={handleFileRemove}
                                            >
                                                X
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-light-gray font-georgia text-base font-medium max-sm:text-sm">(png, jpeg, word, pdf, excel)</p>
                                    )}
                                </div>
                                {errors.file && (
                                    <p className="text-red-500 font-georgia text-justify text-base max-sm:text-sm font-medium ">{errors.file[0]}</p>
                                )}
                            </div>
                            <button className="pulse-gray animation-hover hover:bg-gray-400 bg-main-text shadow-md rounded-[10px] px-8 py-4 text-white font-georgia text-center text-lg font-bold  uppercase  max-sm:text-sm max-md:py-4 max-[500px]:py-2">
                            {t('formButton')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Consultation;