import {
    EmptyIcon,
    ReviewsCloud1,
    ReviewsCloud2,
    arrowLeft,
    arrowRight,
} from "../assets";
import React, { useEffect, useState } from "react";

import { API_URL } from "../constant";
import { fetchReviewData } from "../api";
import { t } from "i18next";

interface Review {
    author: string;
    job_title: string;
    image: string;
    text: string;
    category: {
        name: string;
    };
}

const Reviews = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleItems, setVisibleItems] = useState(6);
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const maxLength = 500;
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);

    const [reviewData, setReviewData] = useState<Review[]>([]);
    const isSingleReview = reviewData.length === 1;

    const fetchData = async () => {
        try {
            const reviewsData = await fetchReviewData();
            console.log(reviewsData);
            setReviewData(reviewsData);
        } catch (error) {
            console.error("Ошибка запроса:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const toggleExpand = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    const PrevArrow = () => {
        setCurrentIndex(
            (prevIndex) =>
                (prevIndex - 1 + reviewData.length) % reviewData.length
        );
        setExpandedIndex(-1);
    };

    const NextArrow = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewData.length);
        setExpandedIndex(-1);
    };

    useEffect(() => {
        const handleCarouselWraparound = () => {
            if (currentIndex < 0) {
                setCurrentIndex(reviewData.length - 1);
            } else if (currentIndex >= reviewData.length) {
                setCurrentIndex(0);
            }
        };

        handleCarouselWraparound();
    }, [currentIndex, reviewData]);

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;

            if (windowWidth < 1024) {
                setVisibleItems(1);
            } else if (windowWidth < 1350) {
                setVisibleItems(2);
            } else if (reviewData.length < 4) {
                setVisibleItems(reviewData.length);
            } else {
                if (windowWidth < 1440) {
                    setVisibleItems(2);
                } else if (windowWidth < 1850) {
                    setVisibleItems(3);
                } else if (windowWidth < 2300) {
                    setVisibleItems(4);
                } else {
                    setVisibleItems(5);
                }
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [reviewData]);

    const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
        setTouchEndX(e.touches[0].clientX);
    };

    const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = () => {
        const touchDiff = touchEndX - touchStartX;

        if (touchDiff > 50) {
            PrevArrow();
        } else if (touchDiff < -50) {
            NextArrow();
        }
    };

    return (
        <div
            className={`relative overflow-hidden flex flex-col max-[1200px]:h-full max-[1200px]:pt-10 justify-center items-start bg-dark-main-bg max-[1200px]:pb-20 max-md:pb-32 h-screen`}
            id="reviews"
        >
            <img
                src={ReviewsCloud1}
                alt="cloud1"
                className="absolute top-24 left-24 z-0 max-lg:left-0 max-lg:top-0 max-md:hidden"
            />
            <img
                src={ReviewsCloud2}
                alt="cloud2"
                className="absolute bottom-0 right-0 max-md:hidden"
            />
            <h1 className="w-full text-center text-white font-georgia font-bold text-[50px] pt-10 mb-12 max-lg:ml-20 max-md:ml-10 max-2xl:text-[40px] max-md:text-[35px] max-sm:mr-5">
                {t("reviewsHeader")}
            </h1>
            <div
                className="relative overflow-hidden"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div
                    className={`z-10 flex justify-center items-center px-2 ${
                        isSingleReview ? "w-1/2 mx-auto max-lg:w-full " : ""
                    } `}
                >
                    {reviewData
                        .concat(reviewData)
                        .slice(currentIndex, currentIndex + visibleItems)
                        .map((review, index) => (
                            <div
                                key={index}
                                className={`w-full p-2 sm:p-4 ${
                                    visibleItems === 1 ||
                                    visibleItems === 2 ||
                                    (visibleItems === 3 && index === 1) ||
                                    (visibleItems === 4 &&
                                        (index === 1 || index === 2)) ||
                                    (visibleItems === 5 &&
                                        (index === 1 ||
                                            index === 2 ||
                                            index === 3)) ||
                                    (visibleItems === 6 &&
                                        (index === 1 ||
                                            index === 2 ||
                                            index === 3 ||
                                            index === 4)) ||
                                    reviewData.length <= 2
                                        ? ""
                                        : "blur-effect "
                                }`}
                            >
                                <div className="flex flex-col items-center rounded-xl bg-white ">
                                    <div
                                        className="text-black text-justify font-georgia text-lg font-normal p-10 min-h-[400px]"
                                        style={{
                                            maxHeight:
                                                expandedIndex === index
                                                    ? "none"
                                                    : "700px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {review.text.length > maxLength ? (
                                            <div className="flex flex-col">
                                                {expandedIndex === index
                                                    ? review.text
                                                    : `${review.text.slice(
                                                          0,
                                                          maxLength
                                                      )}...`}
                                                <button
                                                    onClick={() =>
                                                        toggleExpand(index)
                                                    }
                                                    className="text-main-text text-base cursor-pointer "
                                                >
                                                    {expandedIndex === index
                                                        ? "Скрыть"
                                                        : "Подробнее"}
                                                </button>
                                            </div>
                                        ) : (
                                            review.text
                                        )}
                                    </div>
                                    <div className="flex bg-[#22202B] h-32 w-full rounded-bl-lg rounded-br-lg p-5 items-center max-sm:h-60">
                                        <div className="flex items-center">
                                            <div className="w-20 h-20 flex-shrink-0 relative mr-2">
                                                {review.image ? (
                                                    <img
                                                        src={`${API_URL}/${review.image.replace(
                                                            "public",
                                                            "storage"
                                                        )}`}
                                                        alt={review.author}
                                                        className="w-full h-full object-cover rounded-full"
                                                    />
                                                ) : (
                                                    <img
                                                        src={EmptyIcon}
                                                        alt={review.author}
                                                        className="w-full h-full object-cover rounded-full"
                                                    />
                                                )}
                                            </div>
                                            <div className="flex flex-col items-start mt-2 w-full">
                                                <p className="text-white font-georgia font-bold text-xl">
                                                    {review.author}
                                                </p>
                                                <p className="text-white font-georgia font-normal text-lg">
                                                    {review.job_title}
                                                </p>
                                                <p className="text-white font-georgia font-normal text-lg">
                                                    {review.category.name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                {reviewData.length > 1 && (
                    <>
                        <button
                            onClick={PrevArrow}
                            className="absolute z-20 top-1/2 left-[15%] cursor-pointer transform -translate-y-1/2 px-4 py-2 transition duration-600 ease border-none
                             max-[1440px]:left-[5%] max-md:left-[-2%] max-sm:left-[-5%] max-md:top-[40%]"
                        >
                            <img
                                src={arrowLeft}
                                alt="left"
                                onClick={PrevArrow}
                            />
                        </button>
                        <button
                            onClick={NextArrow}
                            className="absolute z-20 top-1/2 right-[15%] cursor-pointer transform -translate-y-1/2 px-4 py-2 transition duration-600 ease border-none
                             max-[1440px]:right-[5%] max-md:right-[-2%] max-sm:right-[-5%] max-md:top-[40%]"
                        >
                            <img src={arrowRight} alt="right" />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default Reviews;
