import { useEffect, useState } from "react";

import { API_URL } from "./constant";
import { Helmet } from 'react-helmet';
import { Home } from "./pages";
import { fetchMainData } from "./api";

function App() {
  const [faviconContent, setFaviconContent] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [phoneContent, setPhoneContent] = useState('');
  const [vkContent, setVkContent] = useState('');
  const [telegramContent, setTelegramContent] = useState('');
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const mainData = await fetchMainData();
        setFaviconContent(mainData.favicon.replace("public", "storage"));
        setMetaTitle(mainData.meta_title);
        setMetaDescription(mainData.meta_description);
        setPhoneContent(mainData.phone);
        setVkContent(mainData.vk);
        setTelegramContent(mainData.telegram);
  
      } catch (error) {
        console.error('Ошибка запроса:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="icon" href={`${API_URL}/${faviconContent}`} />
      </Helmet>
      <Home metaTitle={metaTitle} metaDescription={metaDescription} phoneContent={phoneContent} vkContent={vkContent} telegramContent={telegramContent} />
    </div>
  );
}

export default App;
